import React from "react"
import { NavBar } from "../NavBar"

import {
  HeaderContainer,
  HeaderInnerContainer,
  TextContainer,
  Title,
  Subtitle,
  HeroImg,
} from "./style"

const Header = ({ pageContext, home }) => {
  const homeHeader = pageContext?.homePageSections?.homeheader

  return (
    <HeaderContainer>
      <NavBar />

      {home && homeHeader && (
        <HeaderInnerContainer>
          <TextContainer>
            <Title
              dangerouslySetInnerHTML={{ __html: homeHeader.headerTitle1 }}
            />{" "}
            <br />
            <Title
              dangerouslySetInnerHTML={{ __html: homeHeader.headerTitle2 }}
            />
            <Subtitle
              dangerouslySetInnerHTML={{ __html: homeHeader.headerDescription }}
            />
          </TextContainer>
          {homeHeader?.headerImage?.sourceUrl && (
            <HeroImg>
              <img src={homeHeader.headerImage.sourceUrl} />
            </HeroImg>
          )}
        </HeaderInnerContainer>
      )}
    </HeaderContainer>
  )
}

export default Header
