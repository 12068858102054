import styled from "styled-components"
import { device } from "breakpoints"

import { FlexCol, FlexRow, FlexGrid } from "../FlexGrid/index"
import { Link } from "gatsby"
import { ReactComponent as ssrvlogo } from "../../images/ssrv-logo.svg"
import { ReactComponent as vlslogo } from "../../images/vls-logo.svg"

export const FooterRightsContainer = styled.div`
  margin: 97px 0;
  margin-bottom: 76px;
  @media ${device.tablet} {
    margin: 120px 0;
    margin-bottom: 96px;
  }
`

export const FooterRightsGrid = styled(FlexGrid)``
export const FooterRightsRow = styled(FlexRow)``
export const FooterRightsCol = styled(FlexCol)``
export const FooterRightsColCopyright = styled(FlexCol)`
  margin-top: 65px;
  position: relative;

  ::before {
    content: "";
    position: absolute;
    top: -50px;
    left: 10px;
    width: calc(100% - 32px);
    height: 1px;
    background: #c5c5c5;
  }
`
export const ImageWrapper = styled.div`
  width: 60px;
  margin-right: 15px;

  @media ${device.desktop} {
    margin-right: 30px;
  }
`
export const FooterRightsSocial = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #000000;
  @media ${device.tablet} {
    font-size: 18px;
    br {
      display: none;
    }
  }
`
export const FooterRightsBottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;

  @media ${device.tablet} {
    flex-direction: row;
    justify-content: flex-start;
  }
`
export const FooterRightsBottomLinks = styled(Link)`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  text-decoration-line: underline;
  color: #000000;
  margin-top: 17px;
  :hover {
    color: #796148;
  }
  @media ${device.tablet} {
    font-size: 18px;
    margin-right: 32px;
  }
`

export const SSRVLogo = styled(ssrvlogo)`
  width: 179px;
  height: auto;
  margin-left: -10px;
  margin-right: 25px;
`
export const VLSLogo = styled(vlslogo)`
  width: 256px;
  height: auto;
  position: relative;
  top: 16px;
`

export const Logos = styled.div`
 padding-top: 25px;
  
`;