import React from "react"
import Img from "gatsby-image/withIEPolyfill"
import { useStaticQuery, graphql } from "gatsby"
const AvenueImg = props => {
  const { placeholderImage } = useStaticQuery(
    graphql`
      query imgPlaceHolder {
        placeholderImage: file(relativePath: { eq: "avenue-placeholder.png" }) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `
  )

  let queryImg, wpImg

  if (props.fluid) {
    // Static Query Image
    queryImg = props.fluid.childImageSharp
      ? props.fluid.childImageSharp.fluid
      : props.fluid.node
      ? props.fluid.node.childImageSharp.fluid
      : props.fluid

    // Wordpress Image
    const wpMedia = props.fluid.mediaDetails || null
    const wpAspectRatio = wpMedia ? wpMedia.width / wpMedia.height : null
    wpImg = props.fluid.sourceUrl &&
      props.fluid.srcSet && {
        aspectRatio: wpAspectRatio || 1,
        sizes: props.fluid.sizes || "100vw",
        src: props.fluid.sourceUrl,
        srcSet: props.fluid.srcSet,
      }
  } else {
    // Placeholder Image
    queryImg = placeholderImage.childImageSharp.fluid
  }

  // GIF Image
  // if (props.src && props.src.includes(".gif") && !props.noAnimate) {
  //   return (
  //     <img
  //       className={props.className}
  //       src={props.src}
  //       alt={props.alt}
  //       loading="lazy"
  //       style={props.style}
  //     />
  //   )
  // }

  // console.log(props.loading)

  // console.log("queryImg:", queryImg)

  return (
    <Img
      ref={props.setRef}
      className={`${props.className} avenueImg`}
      loading={props.loading || "lazy"}
      fadeIn={props.noFade ? false : true}
      fluid={wpImg || queryImg}
      alt={props.alt}
      style={props.style}
    />
  )
}

export default AvenueImg
