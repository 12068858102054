import styled from "styled-components"
import { device } from "breakpoints"

export const FooterContainer = styled.div`
  /* margin-top: 110px; */
  padding-top: 45px;
  position: relative;
  margin-top: -1px;

  ::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 20px;
    height: 1px;
    width: calc(100% - 40px);
    background: #c5c5c5;
  }

  @media ${device.desktop} {
    padding: 100px 0;
    padding-bottom: 0;
    margin: 122px auto;
    margin-bottom: 0;
    max-width: 1060px;
    margin-top: -1px;

    ::before {
      width: calc(100% - 80px);
      left: 40px;
    }
  }
`
