import React, { useState, useContext, useEffect } from "react"
import { Icon } from "../../helpers/getIcon"
import { useStaticQuery, graphql } from "gatsby"
import { PreviewContext } from "context/PreviewContext"

import {
  StickyWrapper,
  StickyBar,
  StickyBarText,
  StickyInnerWrapper,
  IconContainer,
} from "./style"
const Banner = () => {
  const [closePopUp, setClosePopUp] = useState(true)
  const PopUpData = useStaticQuery(
    graphql`
      {
        wpgraphql {
          siteOptions {
            siteSettings {
              showBanner
              bannerText
            }
          }
        }
      }
    `
  )
  const { previewData } = useContext(PreviewContext)

  const stickyBarData =
    previewData?.bannerText ||
    PopUpData?.wpgraphql?.siteOptions?.siteSettings?.bannerText

  const showBanner =
    previewData?.showBanner ||
    PopUpData?.wpgraphql?.siteOptions?.siteSettings?.showBanner

  const getCookie = name => {
    var nameEQ = name + "="
    var ca = document.cookie.split(";")
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i]
      while (c.charAt(0) == " ") c = c.substring(1, c.length)
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
    }
    return null
  }

  const clickedClose = () => {
    var now = new Date()
    var time = now.getTime()
    time += 1800 * 1000
    now.setTime(time)
    document.cookie =
      "bannerClosed=" + "true" + "; expires=" + now.toUTCString() + "; path=/"

    setClosePopUp(true)
  }

  useEffect(() => {
    const bannerClosed = getCookie("bannerClosed")
    if (bannerClosed) {
      setClosePopUp(true)
    } else {
      setClosePopUp(false)
    }
    console.log("bannerClosed:", bannerClosed)
  }, [])

  return (
    <>
      {showBanner && (
        <StickyWrapper closepopup={closePopUp ? "true" : "false"}>
          <StickyBar>
            <StickyInnerWrapper>
              <StickyBarText
                dangerouslySetInnerHTML={{
                  __html: stickyBarData,
                }}
              />

              <IconContainer onClick={() => clickedClose()}>
                <Icon name="close" />
              </IconContainer>
            </StickyInnerWrapper>
          </StickyBar>
        </StickyWrapper>
      )}
    </>
  )
}

export default Banner
