import styled from "styled-components"
import { device } from "../../utils/breakpoints"
import { MainColumn } from "components/MainColumn"

export const HeaderContainer = styled.div`
  width: 100%;
  max-width: 1150px;
  position: relative;
  margin: 0 auto;
  position: relative;
  /* padding-top: 30px; */
  margin-bottom: 34px;

  @media ${device.desktop} {
    margin-bottom: 15px;
  }
`

export const HeaderInnerContainer = styled(MainColumn)`
  margin-top: 100px;
  @media ${device.tablet} {
    /* margin-top: 110px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
  }
`
export const TextContainer = styled.div`
  br {
    display: none;
  }
  @media ${device.tablet} {
    width: 70%;
    br {
      display: block;
    }
  }
`
export const Title = styled.h1`
  font-size: 32px;
  line-height: 42px;
  color: #000000;
  font-style: normal;
  font-weight: 600;
  display: inline;
  @media ${device.tablet} {
    font-size: 50px;
    line-height: 60px;
  }
`
export const Subtitle = styled.h2`
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  font-style: normal;
  font-weight: normal;
  margin-top: 13px;

  @media ${device.tablet} {
    font-size: 24px;
    line-height: 36px;
    margin-top: 23px;
    padding-right: 20%;
  }
`

export const HeroImg = styled.div`
  display: none;
  @media ${device.tablet} {
    display: block;
    position: absolute;
    top: 56px;
    right: 0;
    width: 34.3333%;
  }
`
