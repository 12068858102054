import React from "react"
import { ReactComponent as Search } from "icons/search.svg"
import { ReactComponent as MailIcon } from "icons/mail.svg"
import { ReactComponent as MessageIcon } from "icons/message.svg"
import { ReactComponent as NameIcon } from "icons/name.svg"
import { ReactComponent as PhoneIcon } from "icons/phone.svg"
import { ReactComponent as ArrowLeft } from "icons/arrow-left.svg"
import { ReactComponent as Close } from "icons/close.svg"
import { ReactComponent as ArrowDown } from "icons/arrow-down.svg"

const iconMap = {
  search: { icon: Search },
  mail: { icon: MailIcon },
  message: { icon: MessageIcon },
  name: { icon: NameIcon },
  phone: { icon: PhoneIcon },
  close: { icon: Close },
  "arrow-left": { icon: ArrowLeft },
  "arrow-down": { icon: ArrowDown },
}

export const hasIcon = name => {
  return !!iconMap[name]
}

export const Icon = ({ name, className }) => {
  return iconMap[name]
    ? React.createElement(iconMap[name].icon, { className: className || name })
    : false
}
