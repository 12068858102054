import React, { useState, useContext } from "react"
import NavItem from "./navItem"
import Hamburger from "./hamburger"
import { Icon } from "helpers/getIcon"
import useMedia from "../../hooks/useMedia"
import { ReactComponent as Logo } from "../../images/DSP-logo.svg"

import { useStaticQuery, graphql } from "gatsby"
import { PreviewContext } from "context/PreviewContext"

import {
  LogoWrapper,
  NavBarContainer,
  NavWrapper,
  Nav,
  NavList,
  HamburgerWrapper,
  QuickSearchWrapper,
  FirstLine,
  SecondLine,
  ThirdLine,
  CloseButton,
  HelpContainer,
  TextLink,
  GetHelpButton,
  TextHamburgerContainer,
  Text,
  DesktopNav,
  DesktopNavContainer,
  NavListContainer,
  LogoText,
} from "./style"

const NavBar = () => {
  const [navShown, setNavShown] = useState(false)
  const [desktopNavShown, setDesktopNavShown] = useState(false)
  const isMobile = useMedia(["(max-width: 767px)"], [true], false)
  const [closePopUp, setClosePopUp] = useState(false)
  const { previewData } = useContext(PreviewContext)

  const data = useStaticQuery(
    graphql`
      {
        wpgraphql {
          siteOptions {
            siteSettings {
              nagivationDropdownText
              navigationDropdownItems {
                ... on WPGraphQL_Page {
                  title
                  link
                  pageSettings {
                    pageTheme
                  }
                }
              }
              secondaryNavigationItems {
                isButton
                page {
                  ... on WPGraphQL_Page {
                    title
                    link
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  const navData = previewData || data.wpgraphql.siteOptions.siteSettings

  const toggleShowNav = () => {
    if (isMobile) {
      setNavShown(!navShown)
    } else {
      setNavShown(false)
    }
  }

  const toggleShowDesktopNav = () => {
    if (!isMobile) {
      setDesktopNavShown(!desktopNavShown)
    } else {
      setDesktopNavShown(false)
    }
  }

  return (
    <NavBarContainer>
      <LogoWrapper to="/">
        <Logo />
        <LogoText>By Social Security Rights Victoria</LogoText>
      </LogoWrapper>
      <NavWrapper>
        <Nav navShown={navShown}>
          <CloseButton
            navShown={navShown}
            onClick={() => {
              toggleShowNav()
            }}
          >
            <Icon name="close" />
            Close
          </CloseButton>
          <NavListContainer desktopNavShown={desktopNavShown}>
            <div>
              <DesktopNavContainer
                onClick={() => {
                  toggleShowNav()
                }}
                onKeyUp={event => {
                  event.key === "Enter" && toggleShowDesktopNav()
                }}
              >
                <DesktopNav>{navData.nagivationDropdownText}</DesktopNav>
                <Icon name="arrow-down" />
              </DesktopNavContainer>

              <NavList desktopNavShown={desktopNavShown}>
                {navData?.navigationDropdownItems &&
                  navData.navigationDropdownItems.map((item, index) => {
                    return (
                      <NavItem
                        key={index}
                        label={item.title}
                        path={item.link}
                        color={item.pageSettings.pageTheme}
                      />
                    )
                  })}
              </NavList>
            </div>
          </NavListContainer>
          <HelpContainer>
            {navData.secondaryNavigationItems.map((item, index) => {
              if (item.isButton) {
                return (
                  <GetHelpButton
                    label={item.page.title}
                    link={item.page.link}
                    key={index}
                  />
                )
              } else {
                return (
                  <TextLink to={item.page.link} key={index}>
                    {item.page.title}
                  </TextLink>
                )
              }
            })}
          </HelpContainer>
        </Nav>
        <TextHamburgerContainer
          navShown={navShown}
          onClick={() => {
            toggleShowNav()
          }}
        >
          <Text>Menu</Text>
          <Hamburger navShown={navShown} />
        </TextHamburgerContainer>
      </NavWrapper>
    </NavBarContainer>
  )
}

export default NavBar
