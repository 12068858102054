const primaryColor = "#000"
const white = "#FFFFFF"

//[core, hover, keyline]
const pink = ["#F9C9CD", "#FADFDD", "#E1A4A9"]
const purple = ["#D2C3DF", "#E8E0EF", "#CEB5E4"]
const blue = ["#B5E0E3", "#D3ECEE", "#80CDD2"]
const carolinaBlue = ["#97B6DB", "#E0EAF5", "#80A6D3"]
const green = ["#CFDA8E", "#E4EFA3", "#B9C47A"]
const yellow = ["#FFD800", "#FFEB7F", "#EFCD13"]
const neutral = ["#D3C7BA", "#E5DED6", "#C7B8A8"]

const theme = {
  primaryColor: primaryColor,
  linkColor: primaryColor,
  btnColor: primaryColor,
  btnHoverColor: "#84004b",
  btnTextColor: white,
  pink: pink,
  purple: purple,
  blue: blue,
  carolinaBlue: carolinaBlue,
  green: green,
  yellow: yellow,
  neutral: neutral,
  green: green,
  mobileGutter: 20,
  desktopGutter: 40,
  mainMaxWidth: 992,
  mobileGap: 15,
  tabletGap: 20,
  desktopGap: 35,
  largeGap: 55,
}

export default theme
