import styled from "styled-components"
import { Link } from "gatsby"
import { FlexGrid, FlexRow, FlexCol } from "../FlexGrid/index"

import { device } from "../../utils/breakpoints"

export const MenuItems = styled(Link)`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  text-decoration: underline;
  color: #000000;
  margin-right: 30px;
  margin-top: 25px;
  cursor: pointer;

  @media ${device.tablet} {
    font-size: 18px;
    line-height: 28px;
    margin-top: 20px;
    width: 100%;

    :first-child {
      margin-top: 25px;
      @media ${device.tablet} {
        margin-top: 20px;
      }
    }
  }

  :hover {
    color: #796148;
    transition: 80ms;
  }
`

export const Copyright = styled.p`
  font-size: 12px;
  color: #9cacae;
  letter-spacing: 0;
  /* line-height: 18px; */

  a {
    font-weight: 600;
    text-decoration: none;
    color: ${props => props.theme.primaryColor};
    :hover {
      opacity: 0.6;
      transition: 80ms;
    }
  }
`
export const LogoContainer = styled.div`
  margin-top: 30px;

  @media ${device.tablet} {
    margin-left: 0;
    margin-top: 0;
  }

  svg {
    width: 140px;
    /* height: auto; */
    @media ${device.tablet} {
      width: 156px;
    }
  }
`
export const Menu = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  @media ${device.desktop} {
    margin-top: 12px;
  }
`
export const Menutwo = styled(Menu)`
  @media ${device.tablet} {
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 190px;
    flex: 1;
  }
  @media ${device.desktop} {
    max-height: 233px;
    margin-top: 0;
    width: 100%;
  }
  @media ${device.large} {
    flex-direction: column;
    flex-wrap: wrap;
  }
`
export const Logo = styled.div`
  position: relative;
  a {
    display: flex;
  }

  /* svg {
    height: auto;
  } */
`

export const TextAndLogo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    justify-content: flex-start;
  }
`

export const Text = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  margin-top: 13px;
  width: 70%;
  @media ${device.desktop} {
    font-size: 18px;
    line-height: 24px;
    margin-top: 7px;
    width: 85%;
  }
`
export const FooterHelp = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #000000;

  @media ${device.desktop} {
    display: none;
  }
`

export const HelpMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 50px;

  @media ${device.desktop} {
    margin-top: 40px;
    flex: 1;
  }
`

export const FooterFlexGrid = styled(FlexGrid)`
  max-width: 100%;
`
export const FooterFlexRow = styled(FlexRow)``
export const FooterFlexCol = styled(FlexCol)`
  display: flex;
  flex-direction: column;
  margin-bottom: 0 !important;
  flex: 1 1 auto;
`
