import React from "react"
import {
  Clickable,
  HamburgerWrapper,
  FirstLine,
  SecondLine,
  ThirdLine,
} from "./style.js"

const Hamburger = ({ navShown }) => {
  return (
    <Clickable navShown={navShown}>
      <HamburgerWrapper>
        <FirstLine navShown={navShown} />
        <SecondLine navShown={navShown} />
        <ThirdLine navShown={navShown} />
      </HamburgerWrapper>
    </Clickable>
  )
}

export default Hamburger
