import React, { useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import AvenueImg from "../AvenueImg"
import { PreviewContext } from "context/PreviewContext"

import {
  FooterRightsContainer,
  FooterRightsGrid,
  FooterRightsRow,
  FooterRightsCol,
  ImageWrapper,
  FooterRightsSocial,
  FooterRightsBottomContainer,
  FooterRightsBottomLinks,
  FooterRightsColCopyright,
  Logos,
  VLSLogo,
  SSRVLogo
} from "./footerRightsStyles"

const FooterRights = props => {
  const DataFooterRights = useStaticQuery(
    graphql`
      query flags {
        aboflag: file(relativePath: { eq: "abo-flag.png" }) {
          childImageSharp {
            fluid(maxWidth: 120, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        secflag: file(relativePath: { eq: "sec-flag.png" }) {
          childImageSharp {
            fluid(maxWidth: 120, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        wpgraphql {
          siteOptions {
            siteSettings {
              footerAcknowledgement
              footerCopyright
              footerLinksBottom {
                ... on WPGraphQL_Page {
                  title
                  link
                }
              }
            }
          }
        }
      }
    `
  )
  const { previewData } = useContext(PreviewContext)

  const footerSiteSettings =
    previewData || DataFooterRights?.wpgraphql?.siteOptions?.siteSettings

  const bottomLinks = footerSiteSettings?.footerLinksBottom
  console.log("bottomLinks:", bottomLinks)

  return (
    <FooterRightsContainer>
      <FooterRightsGrid gap={[23]}>
        <FooterRightsRow>
          <FooterRightsCol xs={12}>
            <ImageWrapper>
              <AvenueImg
                fluid={DataFooterRights?.aboflag?.childImageSharp?.fluid}
              />
            </ImageWrapper>
            <ImageWrapper>
              <AvenueImg
                fluid={DataFooterRights?.secflag?.childImageSharp?.fluid}
              />
            </ImageWrapper>
          </FooterRightsCol>
          <FooterRightsCol xs={12}>
            <FooterRightsSocial
              dangerouslySetInnerHTML={{
                __html: footerSiteSettings?.footerAcknowledgement,
              }}
            />
          </FooterRightsCol>
          <FooterRightsColCopyright xs={12}>
            <FooterRightsSocial
              dangerouslySetInnerHTML={{
                __html: footerSiteSettings?.footerCopyright,
              }}
            />
          </FooterRightsColCopyright>
          <FooterRightsCol xs={12}>
            <FooterRightsBottomContainer>
              {bottomLinks.map((item, index) => {
                return (
                  <FooterRightsBottomLinks
                    to={item.link}
                    key={index}
                    dangerouslySetInnerHTML={{
                      __html: item.title,
                    }}
                  ></FooterRightsBottomLinks>
                )
              })}
            </FooterRightsBottomContainer>


          </FooterRightsCol>

          <FooterRightsCol xs={12}>
            
            <Logos>
              <a href="http://www.ssrv.org.au/" target="_blank" rel="noopener noreferrer">
                <SSRVLogo />
              </a>
              <a href="https://lsbc.vic.gov.au/" target="_blank" rel="noopener noreferrer">
                <VLSLogo />
              </a>
            </Logos>

          </FooterRightsCol>
        </FooterRightsRow>
      </FooterRightsGrid>
    </FooterRightsContainer>
  )
}
export default FooterRights
