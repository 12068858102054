import styled from "styled-components"
import { device } from "breakpoints"
import { MainColumn } from "components/MainColumn"
import Sticky from "react-sticky-el"

export const StickyWrapper = styled(Sticky)`
  z-index: 100;
  display: ${props => props.closepopup === "true" && "none"};

  .sticky {
    z-index: 100;
  }
`
export const StickyInnerWrapper = styled(MainColumn)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
export const StickyBar = styled.div`
  width: 100vw;
  background: #000000;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;

  @media ${device.desktop} {
    padding: 23px 0;
  }
`
export const StickyBarText = styled.div`
  p {
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    color: #ffffff;
    width: 100%;
    padding-right: 10px;

    @media ${device.tablet} {
      font-size: 16px;
      line-height: 20px;
      width: 100%;
    }
  }

  a {
    text-decoration: underline;

    :hover {
      color: #d3c7ba;
    }
  }
`
export const IconContainer = styled.button`
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  padding: 0;

  svg {
    height: 16px;
    width: 16px;
    @media ${device.tablet} {
      height: 20px;
      width: 20px;
    }
  }
`
