import React, { useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { ReactComponent as LogoImage } from "../../images/DSP-logo.svg"
import { PreviewContext } from "context/PreviewContext"
// import { SocialLinks } from "../SocialLinks/index"

import {
  MenuItems,
  Copyright,
  LogoContainer,
  Logo,
  Menu,
  TextAndLogo,
  Text,
  FooterHelp,
  HelpMenuContainer,
  FooterFlexGrid,
  FooterFlexRow,
  FooterFlexCol,
  Menutwo,
} from "./footerMenuStyles"

const FooterMenu = props => {
  const footerData = useStaticQuery(
    graphql`
      {
        wpgraphql {
          siteOptions {
            siteSettings {
              footerAcknowledgement
              footerCopyright
              footerLinksBottom {
                ... on WPGraphQL_Page {
                  title
                  link
                }
              }
              footerLinksOne {
                ... on WPGraphQL_Page {
                  title
                  link
                }
              }
              footerLinksTwo {
                ... on WPGraphQL_Page {
                  title
                  link
                }
              }
            }
          }
        }
      }
    `
  )
  const { previewData } = useContext(PreviewContext)

  const data = previewData || footerData?.wpgraphql?.siteOptions?.siteSettings

  return (
    <FooterFlexGrid
      className={props.className}
      gap={[40]}
      rowGap={[30, 50, 60]}
    >
      <FooterFlexRow>
        <FooterFlexCol xs={12} md={4} lg={4}>
          <TextAndLogo>
            <LogoContainer>
              <Logo>
                <a className="avenue" href="/">
                  <LogoImage />
                </a>
              </Logo>
            </LogoContainer>
            <Text>
              A free service provided by Social Security Rights Victoria
            </Text>
          </TextAndLogo>
          <Menu>
            {data.footerLinksOne &&
              data.footerLinksOne.map((item, index) => {
                return (
                  <MenuItems to={item.link} key={index}>
                    {item.title}
                  </MenuItems>
                )
              })}
          </Menu>
        </FooterFlexCol>

        <FooterFlexCol xs={12} md={4} lg={4}>
          <HelpMenuContainer>
            <FooterHelp>How we can help</FooterHelp>
            <Menutwo>
              {data.footerLinksTwo &&
                data.footerLinksTwo.map((item, index) => {
                  return (
                    <MenuItems to={item.link} key={index}>
                      <p>{item.title}</p>
                    </MenuItems>
                  )
                })}
            </Menutwo>
          </HelpMenuContainer>
        </FooterFlexCol>
      </FooterFlexRow>
    </FooterFlexGrid>
  )
}
export default FooterMenu
