import React from "react"
import FooterMenu from "./footerMenu"
import FooterRights from "./footerRights"

import { FooterContainer } from "./footerStyles"

const Footer = () => (
  <FooterContainer>
    <FooterMenu />
    <FooterRights />

  </FooterContainer>
)
export default Footer
