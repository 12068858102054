import React from "react"
import { ReactComponent as Arrow } from "images/icons/arrow-right.svg"
import { Link } from "gatsby"
import { NavListItem } from "./style"

const NavBar = ({ label, path, color }) => {
  return (
    <NavListItem color={color}>
      <Link to={path}>
        <span dangerouslySetInnerHTML={{ __html: label }} />
        <Arrow />
      </Link>
    </NavListItem>
  )
}

export default NavBar
