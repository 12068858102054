import styled from "styled-components"
import { Link } from "gatsby"
import { device } from "breakpoints"
import { MainColumn } from "components/MainColumn"
import { Button } from "../Button"
import { Label } from "../Button/style"

export const NavBarContainer = styled(MainColumn)`
  position: relative;
  justify-content: space-between;
  flex-direction: row;
  padding-top: 20px;
  margin-bottom: 54px;

  @media ${device.desktop} {
    margin-bottom: 70px;
  }

  @media ${device.tablet} {
    align-items: center;
  }
`

export const LogoWrapper = styled(Link)`
  display: flex;
  align-items: center;
  height: auto;

  @media ${device.tablet} {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  > svg,
  > img {
    height: 55px;
    width: 166px;
    display: block;

    @media ${device.tablet} {
      margin-bottom: 4px;
    }
  }
`

export const NavWrapper = styled.div`
  display: flex;
`

export const Nav = styled.nav`
  position: fixed;
  top: 0%;
  right: 0;
  margin: 0;
  background: #f6f6f4;
  width: calc(100% - 42px);
  display: ${props => (props.navShown ? "flex" : "none")};
  z-index: 101;
  padding-top: 35px;
  padding-left: 0;
  padding-bottom: 198px;
  border: 1px solid #000000;
  height: 100vh;
  flex-direction: column;

  @media ${device.desktop} {
    flex-direction: row;
    position: relative;
    bottom: auto;
    width: 100%;
    z-index: 2;
    height: auto;
    display: flex;

    background: none;
    transform: none;
    justify-content: flex-end;
    border: none;
    padding: 0;
  }
`

export const NavList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  height: auto;
  margin-top: 25px;
  margin-bottom: 45px;
  padding-bottom: 40px;
  align-items: flex-start;
  justify-content: flex-start;

  @media ${device.tablet} {
    flex-direction: column;
    display: ${props => (props.desktopNavShown ? "flex" : "none")};
    position: absolute;
    top: 30px;
    left: 0;
    width: 283px;
    border: 1px solid #000;
    background: #f6f6f4;
    padding-top: 13px;
    padding-bottom: 20px;
    z-index: -1;

    :before {
      display: none;
    }
  }
`

export const NavListItem = styled.li`
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  text-decoration-line: underline;
  color: #000000;
  padding: 10px 0;
  position: relative;
  width: 100%;
  padding-left: 25px;
  padding-right: 31px;
  cursor: pointer;

  @media ${device.tablet} {
    width: 100%;
    font-size: 16px;
    line-height: 22px;
  }

  a {
    text-align: left;
    color: inherit;
    background: ${props => props.theme[props.color][0]};
    :hover {
      background: ${props => props.theme[props.color][2]};
    }
    ::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  :hover {
    :before {
      opacity: 1;
    }
  }

  svg {
    height: 10px;
    width: 10px;
    margin-left: 5px;
    margin-right: 6px;
  }
`

export const Clickable = styled.div`
  display: block;
  height: 100%;
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${device.desktop} {
    display: none;
  }
`

export const HamburgerWrapper = styled.div`
  display: inline-block;
  width: 24px;
  height: 16px;
  position: relative;
  cursor: pointer;
`

export const FirstLine = styled.span`
  display: block;
  height: 2px;
  width: 100%;
  transition: 0.4s ease-out;
  background: black;
  position: absolute;
  /* transform: ${props =>
    props.navShown
      ? "translate3d(0, 6px, 0) rotate(45deg)"
      : "translate3d(0, 0, 0) rotate(0deg)"}; */
`

export const SecondLine = styled(FirstLine)`
  /* transition: 0.1s ease-out; */
  /* opacity: ${props => (props.navShown ? 0 : 1)}; */
  top: 8px;
`

export const ThirdLine = styled(FirstLine)`
  /* transform: ${props =>
    props.navShown
      ? "translate3d(0, 6px, 0) rotate(-45deg)"
      : "translate3d(0, 16px, 0) rotate(0deg)"}; */
  display: block;
  top: 16px;
`

export const CloseButton = styled.div`
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #000000;
  display: ${props => (props.navShown ? "inline-flex" : "none")};
  padding-right: 25px;
  margin-left: auto;

  @media ${device.tablet} {
    display: none;
  }

  svg {
    height: 22px;
    width: 22px;
    margin-right: 4px;
    path {
      stroke: #000;
    }
  }
  :hover {
    color: #796148;
    svg path {
      stroke: #796148;
    }
  }
`

export const HelpContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 25px;
  position: relative;

  ::before {
    content: "";
    position: absolute;
    left: 0;
    top: -46px;
    width: 100%;
    height: 1px;
    background: ${props => props.theme.linkColor};
  }

  @media ${device.tablet} {
    flex-direction: row;
    align-items: center;
    margin: 0;
  }
`
export const TextLink = styled(Link)`
  font-family: Graphik;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #000000;
  :hover {
    color: #796148;
  }
  @media ${device.tablet} {
    margin-right: 30px;
  }
`
export const GetHelpButton = styled(Button)`
  margin-top: 23px;
  max-width: 111px;
  border-radius: 0;
  background: #d3c7ba;
  border: 1px solid rgb(255, 216, 0, 0);

  :hover,
  :focus,
  :active {
    background: #000000;
    ${Label} {
      color: #fff;
    }
  }
  :focus {
    border: 1px solid rgb(255, 216, 0);
  }

  @media ${device.tablet} {
    margin-top: 0;
  }

  ${Label} {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
  }
`

export const TextHamburgerContainer = styled.div`
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  display: flex;

  @media ${device.desktop} {
    display: none;
  }
`
export const Text = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #000000;
  margin-right: 10px;
`
export const DesktopNav = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #000000;
  margin-right: 10px;
  /* :hover {
    color: #796148;
  } */
`
export const DesktopNavContainer = styled.button`
  display: none;

  @media ${device.tablet} {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: none;
    background: #f6f6f4;
    height: 56px;
    z-index: 2;
    padding-left: 24px;
    padding-right: 24px;
    margin-right: 9px;

    svg {
      height: 10px;
      width: 10px;
      transform: none;
      transition: 0;
    }
    /* :hover {
      svg path {
        stroke: #796148;
      }
    } */
  }
`
export const NavListContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    cursor: pointer;
    position: relative;
    align-items: center;
    flex-direction: row;
    ${props =>
      props.desktopNavShown &&
      `${DesktopNavContainer} {
               border: 1px solid rgba(0, 0, 0, 1);
               border-bottom: 1px solid rgba(0, 0, 0, 0);
               padding-right: 23px;
               svg {
                 transform: rotate(180deg);
               }
             }`};
    :hover {
      ${NavList} {
        display: flex;
      }

      ${DesktopNavContainer} {
        border: 1px solid rgba(0, 0, 0, 1);
        border-bottom: 1px solid rgba(0, 0, 0, 0);
        padding-right: 23px;
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
`

export const LogoText = styled.p`
  display: none;
  @media ${device.tablet} {
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
  }
`
